<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a class="modal__close modal__close--black" @click="closeModal">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="closeModal"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">New Bank</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit.prevent="saveForm">
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter bank name"
                      v-model="form.name"
                      v-bind:class="{
                        'input-required': formErr.name,
                      }"
                      @input="validateForm"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Sort Code</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter sort code"
                      v-model="form.sortCode"
                      v-bind:class="{
                        'input-required': formErr.sortCode,
                      }"
                      @input="validateSortCode"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">NIBBS Bank Code</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter NIBBS code"
                      v-model="form.nibssBankCode"
                      v-bind:class="{
                        'input-required': formErr.nibssBankCode,
                      }"
                      @input="validateNibbsCode"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">flutterwave bank code</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Flutterwave bank code"
                      v-model="form.flutterWaveBankCode"
                      v-bind:class="{
                        'input-required': formErr.flutterWaveBankCode,
                      }"
                      @input="validateFlutterWaveCode"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Paystack Bank Code</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter paystack bank code"
                      v-model="form.payStackBankCode"
                      v-bind:class="{
                        'input-required': formErr.payStackBankCode,
                      }"
                      @input="validatePayStackCode"
                    />
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    class="button form__button form__button--lg w-30 save-changes"
                    type="submit"
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeModal"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import ApiService from "@/core/services/general.service";
export default Vue.extend({
  name: "CreateBank",
  props: {
    show: Boolean,
    close: Function,
  },
  data() {
    return {
      isSaving: false,
      form: {
        name: "",
        sortCode: "",
        nibssBankCode: "",
        flutterWaveBankCode: "",
        payStackBankCode: "",
      },
      formErr: {
        name: false,
        sortCode: false,
        nibssBankCode: false,
        flutterWaveBankCode: false,
        payStackBankCode: false,
      },
    };
  },
  methods: {
    clearForm() {
      this.form = {
        name: "",
        sortCode: "",
        nibssBankCode: "",
        flutterWaveBankCode: "",
        payStackBankCode: "",
      };
    },
    clearFormValidation() {
      this.formErr = {
        name: false,
        sortCode: false,
        nibssBankCode: false,
        flutterWaveBankCode: false,
        payStackBankCode: false,
      };
    },
    validateForm() {
      this.formErr.name = false;
      if (this.form.name) {
        return true;
      }
      if (!this.form.name) {
        this.formErr.name = true;
      }

      return false;
    },
    validateFlutterWaveCode() {
      this.formErr.flutterWaveBankCode = false;
      if (this.form.flutterWaveBankCode.length <= 15) {
        return true;
      }
      if (this.form.flutterWaveBankCode.length > 15) {
        this.$emit(
          "error-submitting-form",
          "Flutterwave bank code cannot be more than 15 characters long"
        );
        this.formErr.flutterWaveBankCode = true;
      }

      return false;
    },
    validateSortCode() {
      this.formErr.sortCode = false;
      if (this.form.sortCode && this.form.sortCode.length <= 15) {
        return true;
      }
      if (!this.form.sortCode || this.form.sortCode.length > 15) {
        this.$emit(
          "error-submitting-form",
          "Bank sort code cannot be more than 15 characters long"
        );
        this.formErr.sortCode = true;
      }

      return false;
    },
    validateNibbsCode() {
      this.formErr.nibssBankCode = false;
      if (this.form.nibssBankCode && this.form.nibssBankCode.length <= 15) {
        return true;
      }
      if (!this.form.nibssBankCode || this.form.nibssBankCode.length > 15) {
        this.$emit(
          "error-submitting-form",
          "Nibbs bank code cannot be more than 15 characters long"
        );
        this.formErr.nibssBankCode = true;
      }

      return false;
    },
    validatePayStackCode() {
      this.formErr.payStackBankCode = false;
      if (this.form.payStackBankCode.length <= 15) {
        return true;
      }
      if (this.form.payStackBankCode.length > 15) {
        this.$emit(
          "error-submitting-form",
          "Paystack bank code cannot be more than 15 characters long"
        );
        this.formErr.payStackBankCode = true;
      }

      return false;
    },
    closeModal() {
      this.clearForm();
      this.clearFormValidation();
      this.$emit("close");
    },
    saveForm(e) {
      e.preventDefault();
      this.isSaving = false;
      if (
        this.validateForm() &&
        this.validateFlutterWaveCode() &&
        this.validateSortCode() &&
        this.validateNibbsCode() &&
        this.validatePayStackCode()
      ) {
        try {
          this.isSaving = true;
          ApiService.post("Bank", this.form)
            .then((resp) => {
              this.isSaving = false;
              this.$emit("successfully-created-bank", resp.data.message);
              this.clearFormValidation();
              this.clearForm();
              this.$emit("close");
            })
            .catch((error) => {
              this.isSaving = false;
              this.$emit("error-from-endpoint", error.response.data.message);
            });
        } catch (e) {
          this.$emit("error-from-endpoint", e);
        }
      } else {
        this.isSaving = false;
        this.$emit(
          "error-submitting-form",
          "Some fields are required or code length is more than 15 characters long."
        );
      }
    },
  },
});
</script>
